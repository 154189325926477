import React, { Component } from 'react';

class Logout extends Component {
  componentDidMount() {
    this.handleLogout();
  }

  handleLogout = () => {
    // Clear user session data (e.g., authentication tokens, user information)
    localStorage.removeItem('LoginEmail');
    localStorage.removeItem('LoginName');
    localStorage.removeItem('customer_id');

    // Redirect the user to the login page or any other page
    window.location.href = '/login'; // Example: Redirect to the login page
  };

  render() {
    return (
      <div>
        {/* Your component content */}
      </div>
    );
  }
}

export default Logout;
