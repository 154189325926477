import React, { Component } from 'react';

class TestComponent extends Component {
  render() {
    return (
      <div>
        <h1>This is a Test Component</h1>
        <p>Hello, world! This is a test component in React.</p>
      </div>
    );
  }
}

export default TestComponent;
