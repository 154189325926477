import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";


function settingLanguage(lang){ 
    i18n.changeLanguage(lang);
} 
class LoginHeader extends React.Component{
    render(){
        const { t } = this.props;
        const LoginEmail = localStorage.getItem('LoginEmail');
        return (
<header className="header"> 
<nav className="navbar navbar-expand-lg header-nav " style={{'background':'#e6e6e6!important'}}>
    <div className="row w-100" style={{'marginLeft':'0px','marginRight':'0px'}}>

<div className="col-md-12 col-12  hidden-sm hidden-xs" >
<div className="navbar-header" style={{display: "flex"}}>
<a href="/dashboard" className="hidden-xs hidden-sm navbar-brand logo" style={{color: "red", fontSize: "24px", fontWeight: "bold",margin: "0 auto",display: "flex"}}>
<img src="\assets\img\logo.png" style={{width: "200px"}}/>
</a>

 {LoginEmail && (
<a href="/logout" className="hidden-xs hidden-sm navbar-brand " style={{color: "#002f6c", fontSize: "24px", fontWeight: "bold", marginTop: "10px"}}>
LOGOUT


</a>
)}
</div>
</div>

        <div className="col-md-3 col-3 hidden-lg hidden-md">
            <div className="navbar-header" style={{display: "flex"}}>
                <a className="mt-5" id="mobile_btn" href="javascript:void(0);">
                  
                </a>
                <a href="/" style={{ color: "red", fontSize: "24px", fontWeight: "bold" }} className="hidden-xs hidden-sm navbar-brand logo">


                   <img  src="\assets\img\logo.png"  style={{ width: "200px" }}/> 
                </a>
                {LoginEmail && (
<a href="/logout" className="hidden-xs hidden-sm navbar-brand " style={{color: "#002f6c", fontSize: "24px", fontWeight: "bold", marginTop: "10px"}}>
LOGOUT


</a>
)}
            </div>
        </div>

        <div className="col-md-5 col-6">
            <center>
                <a href="/" style={{ color: "red", fontSize: "24px", fontWeight: "bold" }} className=" hidden-lg hidden-md">
                    <img  src="\assets\img\logo.png" style={{ width: "200px" }} /> 
                </a>
            </center>
        

   
</div>

 
   
 
</div>		 


</nav>
</header>
        )
    }

}

export default withTranslation()(LoginHeader);