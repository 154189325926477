import React, { Component, useEffect } from "react";
import LoginHeader from "./Components/LoginHeader";
import Footer from "./Components/Footer";
import axios from "axios";
import { Redirect } from "react-router-dom";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert";
import * as XLSX from 'xlsx';
require("dotenv").config();
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coapplicantmember:false,
      msrppercentagedata: "",
      loading: false,
      isvinChecked: false,
      carname: "",
      model: "",
      year: "",
      currentTab: 0,
      totalTabs: 2,
      showCancelMessage: false,
      showAcceptMessage: false,
      name: [],
      email: [],
      name1: [],
      email1: [],
      msrpvalue: "",
      selectedOption: "84",
      vinno: "",
      phone: [],
      address:[],
      phone1: [],
      address1:[],
      pifrate: 6.5, // Initial value for fuse rate
      conventionalrate: 6,
      expandedIndex: null,
      estimatedresidualvalue: "",
      estimatedresidualvalue2: "",
      estimatedresidualvaluemyname: "",
      prevestimatedresidualvalue: "",
      prevestimatedresidualvalue12: "",
      prevestimatedresidualvalue24: "",
      prevestimatedresidualvalue36: "",
      prevestimatedresidualvalue48: "",
      prevestimatedresidualvalue60: "",
      prevestimatedresidualvalue72: "",
      prevestimatedresidualvalue84: "",
      purchasepricevalue: "",
      totalamountfinanced: 0,
      tradeinvalue: "",
      tradeinpayoff: "",
      downpayment: "",
      insurance: "",
      otherfees: "",
      salestax: "",
      salestaxpercentage: "",
      monthlypayment: "",
      monthlypaymentc: "",
      showPopup: false,
      showcustomerPopup: false,
      showReset12: false,
      showReset24: false,
      showReset36: false,
      showReset48: false,
      showReset60: false,
      showReset72: false,
      showReset84: false,
      selectedTerms: "84",
      selectedIndex: "6",
      notes: "",
      pdf_url: "",
      expandedPanel: "panel1",

       yearData: '',
      makeOptions: [],
      selectedMake: '',
      selectedModel:"",
      excelData: [],

      modelOptions: []
    };
    this.handleTradeinvalue = this.handleTradeinvalue.bind(this);
    this.handleTradeinpayoff = this.handleTradeinpayoff.bind(this);
    this.handleDownpayment = this.handleDownpayment.bind(this);
    this.handleInsurance = this.handleInsurance.bind(this);
    this.handleAllotherfees = this.handleAllotherfees.bind(this);
    this.handlePurchasevalue = this.handlePurchasevalue.bind(this);
    this.handleSalestax = this.handleSalestax.bind(this);
    this.handleSalestaxpercentage = this.handleSalestaxpercentage.bind(this);
    this.handleOverrideCheckboxChange =
      this.handleOverrideCheckboxChange.bind(this);
    this.handleVinno = this.handleVinno.bind(this);
    this.handleGetVin = this.handleGetVin.bind(this);
  
    this.inputRefs = {};
  }

  responseCalcualtion(msrpprice, vehicle_name, image_url) {

    alert("hello");
    this.setState({ msrpvalue: "" });
    this.setState({ model: "" });
    this.setState({ carname: "" });
    this.setState({ year: "" });
    this.setState({ pdf_url: "" });
    this.setState({ purchasepricevalue: "" });
    this.setState({ tradeinvalue: "" });
    this.setState({ tradeinpayoff: "" });
    this.setState({ downpayment: "" });
    this.setState({ insurance: "" });
    this.setState({ otherfees: "" });
    this.setState({ salestax: "" });
    this.setState({ totalamountfinanced: "" });
    const msrpValue = msrpprice;
    this.setState({ msrpvalue: msrpValue });
    const vehicleName = vehicle_name;
    const carArray = vehicleName.split(" ");
    this.setState({ model: carArray.slice(3).join(" ") });
    this.setState({ carname: carArray[1] + " " + carArray[2] });
    this.setState({ year: carArray[0] });

    if (image_url == "/test.png") 
    {
      this.setState({ pdf_url: "./assets/img/sticker.png" });
    } 
    else 
    {
      this.setState({
        pdf_url: process.env.REACT_APP_EXTERNAL_URL + image_url,
      });
    }
    const numericValue = parseFloat(msrpValue.replace(/\$|,/g, "")).toFixed(2);
    var msrp = numericValue;
    var carName =
      carArray[0] +
      " " +
      carArray[1] +
      " " +
      carArray[2] +
      " " +
      carArray.slice(3).join(" ");
    // Call the fetchData function and handle the promise
    this.fetchData(carName).then((data) => {
      var msrppercentage = data.map((value) => value / 2);
      console.log("msrppercentage");
      console.log(msrppercentage);

      this.setState({
        msrppercentagedata: msrppercentage1,
      });
      var msrppercentage1 = msrppercentage.map((value) => value / 4);

       console.log("msrppercentage2");
                console.log(msrppercentage1);
      var estimatedresidualvalue1 = [];
      // Calculate estimated residual value for each percentage
      msrppercentage.forEach((percentage) => {
        var estimaterv = (msrp * percentage) / 100;
        estimatedresidualvalue1.push(estimaterv);
      });

      estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
        parseFloat(value.toFixed(2))
      );

      console.log("hawda2");
      console.log(estimatedresidualvalue1);

      this.setState({
        estimatedresidualvalue: estimatedresidualvalue1,
      });

      this.setState({
        estimatedresidualvaluemyname: estimatedresidualvalue1,
      });

      for (let i = 0; i < estimatedresidualvalue1.length; i++) {
        // Construct state key dynamically
        const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;
        // Set state with the corresponding value
        this.setState({
          [stateKey]: estimatedresidualvalue1[i],
        });
      }

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;

      //const estimatervcArray = this.state.estimatedresidualvalue;

      const estimatervcArray = this.state.estimatedresidualvalue;

      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        // var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2)- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2);

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleAccordionClick = (event) => {
    const accordionButton = event.target.closest(".accordion-header");
    if (accordionButton) {
      const accordionPanel = accordionButton.nextElementSibling;
      if (accordionPanel.classList.contains("collapse")) {
        accordionPanel.classList.remove("collapse");
      } else {
        accordionPanel.classList.add("collapse");
      }
    }
  };


  readFile = () => {
    //const file = 'http://localhost:3000/assets/file/WindowStickerUsageReport.xlsx'; // Replace with your file path
// alert("hello");
const baseurl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
fetch(baseurl+'/assets/file/WindowStickerUsageReport.xlsx')
  .then(response => response.arrayBuffer())
  .then(buffer => {
    // alert("hello");
    const workbook = XLSX.read(buffer, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const excelData = parsedData.slice(1).map(row => ({
      VIN: row[0],
      Year: row[1],
      Make: row[2],
      Model: row[3],
      User: row[4],
      Date: row[5]
    }));
    this.setState({ excelData });
    // alert("hello1");
  })
  .catch(error => {
    // alert("hello2");
    console.error('Error fetching Excel file:', error);
  });


  };


 // Extract unique years from the data
  getYears = () => {
    const { excelData } = this.state;
    console.log(this.state.excelData);
    console.log( [...new Set(excelData.map(item => item.Year))]);
    console.log("excel data");
    
    const uniqueYears = [...new Set(excelData.map(item => item.Year))];
    const sortedYears = uniqueYears.sort((a, b) => a - b);

    return sortedYears;
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleAccordionClick);
  }
  componentDidMount() {

    this.readFile();
    const observer = new MutationObserver((mutationsList, observer) => {
      const accordionPanel = document.querySelector(
        ".accordion-collapse.collapse"
      );
      if (accordionPanel) {
        // Execute your code here when the class is found
        accordionPanel.classList.remove("collapse");
        observer.disconnect(); // Disconnect the observer after executing the code
      }
    });

    // Start observing changes to the target node
    observer.observe(document.body, { childList: true, subtree: true });

    // Handle click event of accordion button to toggle class
    document.addEventListener("click", this.handleAccordionClick);

    // Check if loginName exists in localStorage
    const loginName = localStorage.getItem("LoginEmail");

    // If loginName does not exist, redirect to the login page
    if (!loginName) {
      this.props.history.push("/login");
    } else {
      // alert("hello");
    }

    var buttons = document.querySelectorAll(".wizard-btn");
    //    alert(buttons[0]);
    //    // Check if buttons exist
    //    if (buttons.length > 0) {
    //      // Change text of the first button
    //      buttons[0].textContent = 'Proceed';

    //    }
    // const wizardButtons = document.querySelectorAll('.wizard-btn');
    buttons.forEach((button) => {
      if (button.textContent === "Finish") {
        button.style.display = "none"; // Hide the button
      }
    });

    // for (let i = 12; i <= 84; i += 12) {
    //    const inputValue = this.inputRefs[i].value;
    //    this.setState({
    //      [`prevestimatedresidualvalue${i}`]: inputValue
    //    });
    //  }
    //  this.interval = setInterval(() => {
    // const wizardBtns = document.querySelectorAll('.wizard-btn');

    //    wizardBtns.forEach(btn => {
    //      if (btn.textContent === 'Finish') {
    //        btn.classList.add('hidden');
    //      }

    //      if (btn.textContent === 'Next') {
    //           // btn.textContent = 'Proceed';

    //             if (this.state.vinno == '') {
    //    btn.style.display = 'none';
    //  } else {
    //    btn.textContent = 'Proceed'; // Change the text content if state.vinno is not empty
    //  }

    //      }

    //    });
    //       }, 200);

    const pifRate = this.state.conventionalrate + 0.5; // Adding 0.5% difference
    this.setState({
      pifrate: pifRate,
    });
  }

  checkValidateTab = () => {
    // Validation passed
    return true; // Return true to indicate validation succes
  };

  // Method to generate error messages
  errorMessages = () => {
    const inputValue = this.state.vinno.trim(); // Trim leading and trailing spaces
    return inputValue !== "" ? {} : { tab1: "Field is required" }; // Return error message if input is empty
  };

  changeradio = (index, value) => {
    // alert(index);

    this.setState({ selectedTerms: value });
    this.setState({ selectedIndex: index });
    this.setState({ selectedOption: value });
  };

  openInNewTab = (event) => {
    //window.open(stickerImage, '_blank');
    const imageUrl = event.target.src;
    window.open(imageUrl, "_blank");
  };

  finalQuote = (index) => {
    if (
      this.state.selectedOption === "84" &&
      this.state.monthlypayment[6] !== undefined &&
      this.state.monthlypayment[6] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "72" &&
      this.state.monthlypayment[5] !== undefined &&
      this.state.monthlypayment[5] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "60" &&
      this.state.monthlypayment[4] !== undefined &&
      this.state.monthlypayment[4] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "48" &&
      this.state.monthlypayment[3] !== undefined &&
      this.state.monthlypayment[3] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "36" &&
      this.state.monthlypayment[2] !== undefined &&
      this.state.monthlypayment[2] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "24" &&
      this.state.monthlypayment[1] !== undefined &&
      this.state.monthlypayment[1] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else if (
      this.state.selectedOption === "12" &&
      this.state.monthlypayment[0] !== undefined &&
      this.state.monthlypayment[0] > 0
    ) {
      // Do nothing if the conditions are met

      this.setState({ showPopup: true });
    } else {
      // Hide the popup if the conditions are not met
      this.setState({ showPopup: false });
      Swal({
        text: "You should have PIF monthly rate to confirm.",
        icon: "error",
        button: "OK",
      });
    }
  };

  handleItemClick = (index) => {
    this.setState((prevState) => ({
      expandedIndex: prevState.expandedIndex === index ? null : index,
    }));
  };

  handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };

  handleConventionalRateChange = (e) => {
    if (!this.state.purchasepricevalue) {
      //  alert("Please Fill Purchase Price Field");

      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });

      return false;
    }
    //const newConventionalRate = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);

    const { value } = e.target;
    // if (/^-?\d*\.?\d{0,4}$/.test(value)) {
    //     this.setState({ conventionalrate: value });
    //   }

    // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
    //    const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
    //    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
    //    const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

    //    const formattedValue = sections.length === 2
    //      ? `${integerPart}.${decimalPart}`
    //      : integerPart;
    this.setState({ conventionalrate: value });

    const pifRate = isNaN(value) ? "" : Number(value) + Number(0.5);
    this.setState({
      pifrate: pifRate,
      conventionalrate: value,
    });

    this.setState({ value }, () => {
      // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      //  const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate

        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate,
          this.state.estimatedresidualvalue[index]
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  };

  // error messages
  editDataa = (month) => {
    // this.setState({ estimatedresidualvalue: 0});
    // document.getElementById('editableInput'+month).value = 0;

    if (month == 12) {
      this.setState({ showReset12: true });
    }

    if (month == 24) {
      this.setState({ showReset24: true });
    }

    if (month == 36) {
      this.setState({ showReset36: true });
    }

    if (month == 48) {
      this.setState({ showReset48: true });
    }

    if (month == 60) {
      this.setState({ showReset60: true });
    }

    if (month == 72) {
      this.setState({ showReset72: true });
    }

    if (month == 84) {
      this.setState({ showReset84: true });
    }

    document
      .getElementById("editableInput" + month)
      .removeAttribute("disabled");
  };

  resetDataa = (month) => {
    if (month == 12) {
      //alert("hello");
      this.setState({ showReset12: false });
      console.log(this.state.prevestimatedresidualvalue12);
      console.log("ramdhari");
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue12;

      const updatedArray = [...this.state.estimatedresidualvalue];
      console.log(updatedArray);
      console.log("rajiv");

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[0] = this.state.prevestimatedresidualvalue12;
      console.log("test kaka12");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      console.log(updatedArray);
      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      // Terms for calculation

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[0];
      var monthlyInterestRate = pifRate2;
      var termMonths = 12;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      // console.log(pifMonthlyPayments);
      this.setState({
        monthlypayment: [monthlyPayment, ...this.state.monthlypayment.slice(1)],
      });
    }
    if (month == 24) {
      this.setState({ showReset24: false });

      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue24;

      const updatedArray = [...this.state.estimatedresidualvalue];

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[1] = this.state.prevestimatedresidualvalue24;
      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[1];
      var monthlyInterestRate = pifRate2;
      var termMonths = 24;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(2), // Keep the rest of the elements unchanged
        ],
      });
    }
    if (month == 36) {
      this.setState({ showReset36: false });
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue36;

      const updatedArray = [...this.state.estimatedresidualvalue];

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[2] = this.state.prevestimatedresidualvalue36;
      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[2];
      var monthlyInterestRate = pifRate2;
      var termMonths = 36;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0],
          this.state.monthlypayment[1], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(3), // Keep the rest of the elements unchanged
        ],
      });
    }
    if (month == 48) {
      this.setState({ showReset48: false });
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue48;

      const updatedArray = [...this.state.estimatedresidualvalue];

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[3] = this.state.prevestimatedresidualvalue48;
      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[3];
      var monthlyInterestRate = pifRate2;
      var termMonths = 48;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0],
          this.state.monthlypayment[1],
          this.state.monthlypayment[2], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(4), // Keep the rest of the elements unchanged
        ],
      });
    }
    if (month == 60) {
      this.setState({ showReset60: false });
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue60;

      const updatedArray = [...this.state.estimatedresidualvalue];

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[4] = this.state.prevestimatedresidualvalue60;
      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[4];
      var monthlyInterestRate = pifRate2;
      var termMonths = 60;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0],
          this.state.monthlypayment[1],
          this.state.monthlypayment[2],
          this.state.monthlypayment[3], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(5), // Keep the rest of the elements unchanged
        ],
      });
    }
    if (month == 72) {
      this.setState({ showReset72: false });
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue72;

      const updatedArray = [...this.state.estimatedresidualvalue];

      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      // Update the first index with the value from estimatervcArray
      updatedArray[5] = this.state.prevestimatedresidualvalue72;

      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[5];
      var monthlyInterestRate = pifRate2;
      var termMonths = 72;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0],
          this.state.monthlypayment[1],
          this.state.monthlypayment[2],
          this.state.monthlypayment[3],
          this.state.monthlypayment[4], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(6), // Keep the rest of the elements unchanged
        ],
      });
    }
    if (month == 84) {
      this.setState({ showReset84: false });
      document.getElementById("editableInput" + month).value =
        this.state.prevestimatedresidualvalue84;

      const updatedArray = [...this.state.estimatedresidualvalue];

         
      console.log("mainesocha");

      // Update the first index with the value from estimatervcArray

      console.log(this.state.prevestimatedresidualvalue84);
      console.log("mainesocha2" + this.state.prevestimatedresidualvalue84);
      // Update all other values in the array to match the previous array
      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
      // }

      // for (let i = 1; i < updatedArray.length; i++) {
      //   updatedArray[i] = this.state.estimatedresidualvalue[i];
      // }

      updatedArray[6] = this.state.prevestimatedresidualvalue84;

      console.log("test kaka");

      console.log(updatedArray);
      this.setState({
        estimatedresidualvalue: updatedArray,
      });

      // this.setState({
      //   estimatedresidualbynamevalue: updatedArray,

      // });

      const pifRate2 = this.state.pifrate / 100 / 12;
      console.log("kana");
      console.log(updatedArray);
      const estimatervcArray = updatedArray;
      var loanAmount = this.state.totalamountfinanced.toFixed(2);
      var endBalance = updatedArray[6];
      var monthlyInterestRate = pifRate2;
      var termMonths = 84;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      console.log(monthlyPayment);

      this.setState({
        monthlypayment: [
          this.state.monthlypayment[0],
          this.state.monthlypayment[1],
          this.state.monthlypayment[2],
          this.state.monthlypayment[3],
          this.state.monthlypayment[4],
          this.state.monthlypayment[5], // Keep the first element unchanged
          monthlyPayment, // Update the second element
          ...this.state.monthlypayment.slice(7), // Keep the rest of the elements unchanged
        ],
      });
    }

    document
      .getElementById("editableInput" + month)
      .setAttribute("disabled", "disabled");
  };

  editData = () => {
    this.setState({ estimatedresidualvalue: 0 });
    document.getElementById("editableInput").value = 0;
    document.getElementById("editableInput").removeAttribute("disabled");
  };

  updateEstimateresiduavalue = (data) => {
    this.setState({
      estimatedresidualvalue: data,
    });
  };

  // numberWithCommas(x) {
  //    // Check if x is a valid number
  //    if (isNaN(x)) return '';

  //    // Split the number into integer and decimal parts
  //    var parts = x.toString().split(".");
  //    // Add commas to the integer part
  //    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //    // Join the integer and decimal parts with the decimal point
  //    return parts.join(".");
  //  }

  handlePurchasevalue(e) {
    const { value } = e.target;
    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    this.setState({ purchasepricevalue: formattedValue });

    this.setState({ value }, () => {
      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      this.setState({ totalamountfinanced: totalamounttobefinanced });
      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly

console.log("purchasechange"+this.state.msrppercentagedata)
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1

          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;

          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //   estimatedresidualvaluemyname:modifiedArray
      // });
      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        // const monthlyInterestRate = pifRate;
        //   return (totalLoanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -termMonths));

        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];
      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
        var toalamountfinceddata = totalamounttobefinanced;
        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }


appendcoapplicant =()=>
{
   this.setState({
       
        coapplicantmember:true,
      });

}
  handleTradeinpayoff(e) {
    // const tradeinpayoff = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);

    if (!this.state.purchasepricevalue) {
      // alert("Please Fill Purchase Price Field");
      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });

      return false;
    }

    const { value } = e.target;

    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;

    // if (/^-?\d*\.?\d{0,4}$/.test(value)) {

    this.setState({ tradeinpayoff: formattedValue });
    // }

    this.setState({ value }, () => {
      // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      //  const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          // const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        // const monthlyInterestRate = pifRate;
        //   return (totalLoanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -termMonths));

        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          toalamountfinceddata,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleSalestax(e) {
    //  const salestax = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

    const { value } = e.target;
    // if (/^-?\d*\.?\d*$/.test(value)) {

    // }

    // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
    //     const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
    //     const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
    //     const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

    //     const formattedValue = sections.length === 2
    //       ? `${integerPart}.${decimalPart}`
    //       : integerPart;
    this.setState({ salestax: value });

    this.setState({ value }, () => {
      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);
      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });
    });
  }

  handleSalestaxpercentage(e) {
    // const salestaxpercentage = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

    const { value } = e.target;
    // if (/^-?\d*\.?\d*$/.test(value)) {
    //      this.setState({ salestaxpercentage: value });
    //    }

    // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
    //     const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
    //     const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
    //     const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

    //     const formattedValue = sections.length === 2
    //       ? `${integerPart}.${decimalPart}`
    //       : integerPart;
    this.setState({ salestaxpercentage: value });

    this.setState({ value }, () => {
      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      //const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment.replace(/,/g, '')) || 0) + (parseFloat(this.state.insurance.replace(/,/g, '')) || 0) + (parseFloat(this.state.otherfees.replace(/,/g, '')) || 0) + (parseFloat(this.state.salestax.replace(/,/g, '')) || 0);

      //const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      const totalamounttobeforsalescalc1 =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0);

      const salestaxpercentageDecimal = value / 100;
      const totalamounttobeforsalescalc =
        totalamounttobeforsalescalc1 * salestaxpercentageDecimal;

      this.setState({ salestax: totalamounttobeforsalescalc });

      const adjustedvehicleprice1 =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice1 -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(totalamounttobeforsalescalc) || 0);
      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      // var msrppercentage1=[8,7,5,4,2,2,1];
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          //const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleTradeinvalue(e) {
    if (!this.state.purchasepricevalue) {
      //alert("Please Fill Purchase Price Field");

      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    //const tradeinvalue = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;
    const { value } = e.target;
    // if (/^-?\d*\.?\d*$/.test(value)) {
    //      this.setState({ tradeinvalue: value });
    //    }

    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    this.setState({ tradeinvalue: formattedValue });

    this.setState({ value }, () => {
      // alert(tradeinvalue);
      // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      // const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      // var msrppercentage1=[8,7,5,4,2,2,1];
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          //  const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleDownpayment(e) {
    if (!this.state.purchasepricevalue) {
      // alert("Please Fill Purchase Price Field");

      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    //const downpayment = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

    const { value } = e.target;
    // if (/^-?\d*\.?\d*$/.test(value)) {
    //      this.setState({ downpayment: value });
    //    }

    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    this.setState({ downpayment: formattedValue });

    this.setState({ value }, () => {
      //  const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      // const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);




      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          //const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      console.log("natiedown");
      console.log(pifMonthlyPayments);
      console.log(totalamounttobefinanced);

      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleInsurance(e) {
    //const insurance = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;
    if (!this.state.purchasepricevalue) {
      //alert("Please Fill Purchase Price Field");

      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    const { value } = e.target;
    // if (/^-?\d*\.?\d*$/.test(value)) {
    //      this.setState({ insurance: value });
    //    }

    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    this.setState({ insurance: formattedValue });

    this.setState({ value }, () => {
      //  const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
      // const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      // var msrppercentage1=[8,7,5,4,2,2,1];
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          // const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);

      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          toalamountfinceddata,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handleAllotherfees(e) {
    // const otherfees = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

    if (!this.state.purchasepricevalue) {
      //alert("Please Fill Purchase Price Field");

      Swal({
        text: "Please Fill Purchase Price Field",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    const { value } = e.target;

    const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    this.setState({ otherfees: formattedValue });

    this.setState({ value }, () => {
      // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + parseFloat(this.state.tradeinpayoff || 0);
      //  const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

      const adjustedvehicleprice =
        parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
        (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
      const totalamounttobefinanced =
        adjustedvehicleprice -
        (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
        (parseFloat(this.state.salestax) || 0);

      // alert(totalamounttobefinanced);
      this.setState({ totalamountfinanced: totalamounttobefinanced });

      let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
      console.log("target", estimatervcArray1);
      // var msrppercentage1=[8,7,5,4,2,2,1];
      var msrppercentage1 = this.state.msrppercentagedata;
      // Create a new array to store the modified values
      let modifiedArray = [];
      // Iterate through the array
      for (let i = 0; i < estimatervcArray1.length; i++) {
        // Check if the array element is greater than the target value
        const targetValue = totalamounttobefinanced;

        if (parseInt(estimatervcArray1[i]) > targetValue) {
          // Calculate the new value based on msrppercentage1
          // const newValue1 =  (this.state.msrpvalue * msrppercentage1[i]) / 100;
          const numericValue = parseFloat(
            this.state.msrpvalue.replace(/\$|,/g, "")
          ).toFixed(2);
          const newValue1 = (numericValue * msrppercentage1[i]) / 100;
          // Push the new value to the modifiedArray
          modifiedArray.push(newValue1);
        } else {
          // If the value is not greater than the target value, push the original value
          modifiedArray.push(estimatervcArray1[i]);
        }
      }

      // Set the state with the modified array
      console.log(
        "Previous estimatedresidualvalue:",
        this.state.estimatedresidualvaluemyname
      );
      console.log("Modified Array:", modifiedArray);
      modifiedArray = modifiedArray.map((value) =>
        parseFloat(value.toFixed(2))
      );

      this.setState({
        estimatedresidualvalue: modifiedArray,
      });

      // this.setState({
      //                           estimatedresidualvaluemyname:modifiedArray
      //                         });

      this.setState({
        prevestimatedresidualvalue12: modifiedArray[0],
      });

      this.setState({
        prevestimatedresidualvalue24: modifiedArray[1],
      });

      this.setState({
        prevestimatedresidualvalue36: modifiedArray[2],
      });
      this.setState({
        prevestimatedresidualvalue48: modifiedArray[3],
      });
      this.setState({
        prevestimatedresidualvalue60: modifiedArray[4],
      });
      this.setState({
        prevestimatedresidualvalue72: modifiedArray[5],
      });
      this.setState({
        prevestimatedresidualvalue84: modifiedArray[6],
      });

      const calculateMonthlyPaymentC = (
        totalLoanAmount,
        termMonths,
        monthlyInterestRate
      ) => {
        return (
          (totalLoanAmount * monthlyInterestRate) /
          (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
        );
      };
      const calculateMonthlyPayment = (
        totalLoanAmount,
        termMonths,
        pifRate,
        endBalance
      ) => {
        var loanAmount = totalLoanAmount;
        var endBalance = endBalance;
        var monthlyInterestRate = pifRate;
        var termMonths = termMonths;
        let monthlyPayment = 0;
        let epsilon = 0.01; // Tolerance for convergence
        let maxIterations = 1000; // Maximum number of iterations

        for (let i = 0; i < maxIterations; i++) {
          let remainingBalance = loanAmount;

          // Calculate remaining balance using the current monthly payment
          for (let j = 0; j < termMonths; j++) {
            let interest = remainingBalance * monthlyInterestRate;
            let principal = monthlyPayment - interest;
            remainingBalance -= principal;
          }

          // Check if the remaining balance is close enough to the end balance
          if (Math.abs(remainingBalance - endBalance) < epsilon) {
            break; // Stop iteration if close enough
          }

          // Adjust monthly payment based on the difference between calculated remaining balance and end balance
          monthlyPayment += (remainingBalance - endBalance) / termMonths;
        }

        return monthlyPayment;
      };

      // Terms for calculation
      const terms = [12, 24, 36, 48, 60, 72, 84];
      const conventionalRate = this.state.conventionalrate / 100 / 12;
      const pifRate2 = this.state.pifrate / 100 / 12;
      const estimatervcArray = this.state.estimatedresidualvalue;
      let pifMonthlyPayments = [];
      let conventionalMonthlyPayments = [];

      // estimatervcArray.forEach(estimatervcValue => {
      terms.forEach((term, index) => {
        // // Calculate monthly payment for PIF rate
        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

        var toalamountfinceddata = totalamounttobefinanced;

        const pifMonthlyPayment = calculateMonthlyPayment(
          toalamountfinceddata,
          term,
          pifRate2,
          this.state.estimatedresidualvalue[index]
        );
        pifMonthlyPayments.push(pifMonthlyPayment);
        // Calculate monthly payment for conventional rate
        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
          this.state.totalamountfinanced,
          term,
          conventionalRate
        );
        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
      });
      //});
      this.setState({
        monthlypayment: pifMonthlyPayments,
        monthlypaymentc: conventionalMonthlyPayments,
      });
    });
  }

  handlerv12 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue12;

    if (newValue > previousValue) {
      // alert("You can't select a value greater than the previous value.");

      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });

      return;
    }

    const updatedArray = [...this.state.estimatedresidualvaluemyname];

    console.log(updatedArray);
    console.log("rajiv");

    // Update the first index with the value from estimatervcArray
    updatedArray[0] = newValue;

    // Update all other values in the array to match the previous array
    for (let i = 1; i < updatedArray.length; i++) {
      updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
    }

    console.log("test kaka");

    console.log(updatedArray);
    this.setState({
      estimatedresidualvalue: updatedArray,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray,
    });

    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);
    //        // alert(totalamounttobefinanced);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      // var toalamountfinceddata = this.state.totalamountfinanced
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlerv24 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue24;

    if (newValue > previousValue) {
      //alert("You can't select a value greater than the previous value.");
      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });

      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[1] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray1,
    });

    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    // alert(totalamounttobefinanced);
    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlePrevButtonClick = () => {
    const { currentTab } = this.state;
    // Logic to handle clicking the "previous" button
    if (currentTab > 0) {
      this.setState({ currentTab: currentTab - 1 });
    }
  };

  handleNextButtonClick = () => {
    var vindata = this.state.vinno.trim();

    if (vindata == "") {
      // alert("Please Fill Vin No.Field");

      Swal({
        text: "Please Fill Vin No.Field.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    // alert(vindata.length);

    if (vindata.length < 17 || vindata.length > 17) {
      // alert("Please Fill Vin No.Field");

      Swal({
        text: "Vin no. should be 17 digit number",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    // alert("ejbjb");
    const { currentTab, totalTabs } = this.state;
    // Logic to handle clicking the "next" button
    // alert(currentTab);
    // alert(totalTabs);
    if (currentTab < totalTabs - 1) {
      this.setState({ currentTab: currentTab + 1 });

      //alert(this.state.currentTab);
    }

  };

  handlerv36 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue36;

    if (newValue > previousValue) {
      // alert("You can't select a value greater than the previous value.");

      Swal({
        text: "You can't select a value greater than the previous value",
        icon: "error",
        button: "OK",
      });

      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[2] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray1,
    });

    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    // alert(totalamounttobefinanced);
    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlerv48 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue48;

    if (newValue > previousValue) {
      // alert("You can't select a value greater than the previous value.");

      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });

      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[3] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray1,
    });

    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    // alert(totalamounttobefinanced);
    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlerv60 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue60;

    if (newValue > previousValue) {
      // alert("You can't select a value greater than the previous value.");

      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[4] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray1,
    });
    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    // alert(totalamounttobefinanced);
    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlerv72 = (e) => {
    const newValue = parseFloat(e.target.value);
    const previousValue = this.state.prevestimatedresidualvalue72;

    if (newValue > previousValue) {
      //alert("You can't select a value greater than the previous value.");

      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[5] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    this.setState({
      estimatedresidualvaluemyname: updatedArray1,
    });
    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    // alert(totalamounttobefinanced);
    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  handlerv84 = (e) => {
    const newValue = parseFloat(e.target.value);

    // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
    //     const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
    //     const integerPart = sections[0]; // Add commas for thousand separators
    //     const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

    //     const formattedValue = sections.length === 2
    //       ? `${integerPart}.${decimalPart}`
    //       : integerPart;

    //  this.setState({
    //     estimatedresidualvalue: [formattedValue, ...this.state.estimatedresidualvalue.slice(1)]
    //   });

    const previousValue = this.state.prevestimatedresidualvalue84;

    if (newValue > previousValue) {
      // alert("You can't select a value greater than the previous value.");
      Swal({
        text: "You can't select a value greater than the previous value.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    const updatedArray1 = [...this.state.estimatedresidualvalue];
    console.log("razai");
    console.log(updatedArray1);

    updatedArray1[6] = newValue;

    console.log("test kaka");

    console.log(updatedArray1);
    this.setState({
      estimatedresidualvalue: updatedArray1,
    });

    // this.setState({
    //   estimatedresidualvaluemyname: updatedArray1,

    // });

    // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //        const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);
    //        // alert(totalamounttobefinanced);
    const adjustedvehicleprice =
      parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
      (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    const totalamounttobefinanced =
      adjustedvehicleprice -
      (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
      (parseFloat(this.state.salestax) || 0);

    this.setState({ totalamountfinanced: totalamounttobefinanced });

    const calculateMonthlyPayment = (
      totalLoanAmount,
      termMonths,
      pifRate,
      endBalance
    ) => {
      var loanAmount = totalLoanAmount;
      var endBalance = endBalance;
      var monthlyInterestRate = pifRate;
      var termMonths = termMonths;
      let monthlyPayment = 0;
      let epsilon = 0.01; // Tolerance for convergence
      let maxIterations = 1000; // Maximum number of iterations

      for (let i = 0; i < maxIterations; i++) {
        let remainingBalance = loanAmount;

        // Calculate remaining balance using the current monthly payment
        for (let j = 0; j < termMonths; j++) {
          let interest = remainingBalance * monthlyInterestRate;
          let principal = monthlyPayment - interest;
          remainingBalance -= principal;
        }

        // Check if the remaining balance is close enough to the end balance
        if (Math.abs(remainingBalance - endBalance) < epsilon) {
          break; // Stop iteration if close enough
        }

        // Adjust monthly payment based on the difference between calculated remaining balance and end balance
        monthlyPayment += (remainingBalance - endBalance) / termMonths;
      }

      return monthlyPayment;
    };

    // Terms for calculation
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const conventionalRate = this.state.conventionalrate / 100 / 12;
    const pifRate2 = this.state.pifrate / 100 / 12;
    const estimatervcArray = updatedArray1;
    let pifMonthlyPayments = [];
    let conventionalMonthlyPayments = [];

    // estimatervcArray.forEach(estimatervcValue => {
    terms.forEach((term, index) => {
      // // Calculate monthly payment for PIF rate
      console.log(this.state.totalamountfinanced);
      console.log(estimatervcArray[index]);
      console.log("raju");
      //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
      var toalamountfinceddata = totalamounttobefinanced;

      const pifMonthlyPayment = calculateMonthlyPayment(
        toalamountfinceddata,
        term,
        pifRate2,
        estimatervcArray[index]
      );
      pifMonthlyPayments.push(pifMonthlyPayment);
    });
    //});
    this.setState({
      monthlypayment: pifMonthlyPayments,
    });
  };

  // error messages
  errorMessages = () => {
    // you can add alert or console.log or any thing you want
    // alert("Please fill in the required fields");
  };

  handleNextClick = () => {
    //this.setState({ showPopup: false});
    this.setState({ showPopup: false });
    this.setState({ showcustomerPopup: true });
  };

  handleNextClick1 = () => {
    //this.setState({ showPopup: false});
    // alert("hello");

  if (this.state.name.length== 0) {
      Swal({
        text: "Please enter all names.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

   
if (this.state.email.length == 0) 
     {
      // alert('Please enter email.');

      Swal({
        text: "Please enter email.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    // if (reg.test(this.state.email) === false) {
    //   // alert('Please enter correct email.');

    //   Swal({
    //     text: "Please enter correct email.",
    //     icon: "error",
    //     button: "OK",
    //   });

    //   return false;
    // }

    if (this.state.phone.length==0) {
      //alert('Please enter surname.');

      Swal({
        text: "Please enter surname.",
        icon: "error",
        button: "OK",
      });

      return false;
    }


const clientData1 = new URLSearchParams({
    dealer_id: localStorage.getItem("customer_id"),
    customer_id: '',
   car_name: this.state.carname,
      model: this.state.model,
      year: this.state.year,
      msrp_value: this.state.msrpvalue,
      msrp_percentage: "",
      residual_value: "",
      purchasepricevalue: this.state.purchasepricevalue,
      trade_in_value: this.state.tradeinvalue,
      trade_in_payoff: this.state.tradeinpayoff,
      downpayments: this.state.downpayment,
      insurance: this.state.insurance,
      salestax: this.state.salestax,
      total_financed_amount: this.state.totalamountfinanced
        ? parseFloat(this.state.totalamountfinanced).toFixed(2)
        : "",
      conventional_rate: this.state.conventionalrate,
      pif_rate: this.state.pifrate,
      notes: this.state.notes,
      all_other_fees: this.state.otherfees,
      per_month_pif_amount:
        this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
        this.state.monthlypayment[this.state.selectedIndex] > 0
          ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
          : "",
      per_month_conventioal_amount:
        this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
        this.state.monthlypaymentc[this.state.selectedIndex] !== 0
          ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
          : "",


  });

if (this.state.name[0]) {
    clientData1.append('name[0]', this.state.name[0]);
}
if (this.state.email[0]) {
    clientData1.append('email[0]', this.state.email[0]);
}
if (this.state.phone[0]) {
    clientData1.append('phone[0]', this.state.phone[0]);
}
if (this.state.address[0]) {
    clientData1.append('address[0]', this.state.address[0]);
}
if (this.state.name1[0]) {
    clientData1.append('name[1]', this.state.name1[0]);
}
if (this.state.email1[0]) {
    clientData1.append('email[1]', this.state.email1[0]);
}
if (this.state.phone1[0]) {
    clientData1.append('phone[1]', this.state.phone1[0]);
}
if (this.state.address1[0]) {
    clientData1.append('address[1]', this.state.address1[0]);
}

// const clientData1 = {
//   dealer_id: 1,
//   customer_id: "", // Assuming this is optional since it's empty
//   car_name: "xyz",
//   model: "abc",
//   year: 1992,
//   msrp_value: 10000,
//   msrp_percentage: "", // Empty string if not provided
//   residual_value: "", // Same as above
//   trade_in_value: 1000,
//   trade_in_payoff: 2000,
//   downpayments: 1000,
//   insurance: 1000,
//   sales_tax: 10,
//   total_financed_amount: 100000,
//   pif_rate: 4,
//   conventional_rate: 4,
//   notes: "hello",
//   all_other_fees: 200,
//   per_month_pif_amount: 134.50,
//   per_month_conventional_amount: 22.21,
//   customers: [
//     {
//       name: "customer",
//       email: "customer@gmail.com",
//       phone: "2345671230",
//       address: "test"
//     },
//     {
//       name: "customer1",
//       email: "customer1@gmail.com",
//       phone: "23456712301",
//       address: "test1"
//     }
//   ]
// };

console.log("laaa");
console.log(clientData1);

    // const clientData1 = {
    //   name: this.state.name,
    //   email: this.state.email,
    //   phone: this.state.phone,
    //   dealer_id: localStorage.getItem("customer_id"),
    //   customer_id: 1,
    //   car_name: this.state.carname,
    //   model: this.state.model,
    //   year: this.state.year,
    //   msrp_value: this.state.msrpvalue,
    //   msrp_percentage: "",
    //   residual_value: "",
    //   purchasepricevalue: this.state.purchasepricevalue,
    //   trade_in_value: this.state.tradeinvalue,
    //   trade_in_payoff: this.state.tradeinpayoff,
    //   downpayments: this.state.downpayment,
    //   insurance: this.state.insurance,
    //   salestax: this.state.salestax,
    //   total_financed_amount: this.state.totalamountfinanced
    //     ? parseFloat(this.state.totalamountfinanced).toFixed(2)
    //     : "",
    //   conventional_rate: this.state.conventionalrate,
    //   pif_rate: this.state.pifrate,
    //   notes: this.state.notes,
    //   all_other_fees: this.state.otherfees,
    //   per_month_pif_amount:
    //     this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
    //     this.state.monthlypayment[this.state.selectedIndex] > 0
    //       ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
    //       : "",
    //   per_month_conventioal_amount:
    //     this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
    //     this.state.monthlypaymentc[this.state.selectedIndex] !== 0
    //       ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
    //       : "",
    // };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/InsertAcceptQuote`,
        clientData1
      )
      .then((res) => {
        if (res.data.Status) {
          Swal({
            text: res.data.Message,
            icon: "success",
            button: "OK",
          });


          
this.setState({ coapplicantmember: false });

this.setState({ name: [] });
this.setState({ email: [] });
this.setState({ phone: [] });
this.setState({ address: [] });
this.setState({ name1: [] });
this.setState({ email1: [] });
this.setState({ phone1: [] });
this.setState({ address1: [] });

          this.setState({ showcustomerPopup: false });

          this.setState({ showAcceptMessage: true });

          // Revert back to the original state after 5 seconds
          setTimeout(() => {
            this.setState({ showAcceptMessage: false });
          }, 5000);
        }
        // alert(res.data.Message);
        else
          Swal({
            text: res.data.Message,
            icon: "error",
            button: "OK",
          });
      }) .catch((error) => {
    console.error('Error posting data:', error);
    Swal({
      text: `Error: ${error.message}`,
      icon: "error",
      button: "OK",
    });
  });
  };

  handleCancelClick = () => {
    this.setState({ showPopup: false });

    this.setState({ showCancelMessage: true });

    // Revert back to the original state after 5 seconds
    setTimeout(() => {
      this.setState({ showCancelMessage: false });
    }, 5000);
  };

  fetchData = (carName) => {
    let msrpPercentages = [];
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetVehiclePercentage/${carName}`
        )
        .then((secondRes) => {
          msrpPercentages.push(secondRes.data[0]["12MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["24MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["36MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["48MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["60MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["72MonthsMsrpPercentage"]);
          msrpPercentages.push(secondRes.data[0]["84MonthsMsrpPercentage"]);

          // console.log(
          //   "msrpPercentages inside Axios callback:",
          //   msrpPercentages
          // );
          console.log("carname" + secondRes.data[0]["carname"]);
          console.log("year" + secondRes.data[0]["year"]);
          console.log("model" + secondRes.data[0]["make"]);

          // Resolve the promise with the updated msrpPercentages array
          resolve(msrpPercentages);
        })
        .catch((error) => {
          console.error("Error in second request:", error);
          // Reject the promise with the error
          reject(error);
        });
    });
  };

  handleOverrideCheckboxChange = () => {
    // alert("djdj");
    const observer = new MutationObserver((mutationsList, observer) => {
      const accordionPanel = document.querySelector(
        ".accordion-collapse.collapse"
      );
      if (accordionPanel) {
        // Execute your code here when the class is found
        accordionPanel.classList.remove("collapse");
        observer.disconnect(); // Disconnect the observer after executing the code
      }
    });

    // Start observing changes to the target node
    observer.observe(document.body, { childList: true, subtree: true });

    // alert(this.state.isvinChecked);
    this.setState({ isvinChecked: !this.state.isvinChecked });

    // alert(this.state.isvinChecked);

    if (this.state.isvinChecked == true) {
      this.setState({ vinno: "" });
    }

    if (this.state.isvinChecked == false) {
      //this.setState({ vinno: '1HGCY1F34RA034366 '});

      this.setState({ vinno: "1HGCY1F34RA034366" }, () => {
        // alert("hello");

        this.setState({ loading: true });

        this.setState({ msrpvalue: "" });
        this.setState({ model: "" });
        this.setState({ carname: "" });
        this.setState({ year: "" });
        this.setState({ pdf_url: "" });
        this.setState({ purchasepricevalue: "" });
        this.setState({ tradeinvalue: "" });
        this.setState({ tradeinpayoff: "" });
        this.setState({ downpayment: "" });
        this.setState({ insurance: "" });
        this.setState({ otherfees: "" });
        this.setState({ salestax: "" });

        
        axios
          .get(process.env.REACT_APP_EXTERNAL_URL + `/1HGCY1F34RA034366`)
          .then((res) => 
          {
            this.setState({ loading: false });

            // alert(res.data.status);
            if (res.data.status == false) {
              Swal({
                text: "Data is not available for this vin",
                icon: "error",
                button: "OK",
              });
              return false;
            }

            console.log(res);
            const responseData = res.data;

            // Extract the vehicle name from the response data
            const vehicleName = responseData.vehicle_name;
            const msrpValue = responseData.price;
            this.setState({ msrpvalue: msrpValue });
            const carArray = vehicleName.split(" ");
            this.setState({ model: carArray.slice(3).join(" ") });
            this.setState({ carname: carArray[1] + " " + carArray[2] });
            this.setState({ year: carArray[0] });

            this.setState({
              pdf_url:
                process.env.REACT_APP_EXTERNAL_URL + responseData.img_url,
            });

            const numericValue = parseFloat(
              msrpValue.replace(/\$|,/g, "")
            ).toFixed(2);

            var carName = vehicleName;

            //let msrpPercentages = [];

            // Call the fetchData function and handle the promise
            var msrp = "";
            this.fetchData(carName)
              .then((data) => {
                // Access the updated msrpPercentages array here

                var msrp = numericValue;
                //var msrppercentage=msrpPercentages;
                var msrppercentage = data.map((value) => value / 2);

                var msrppercentage1 = msrppercentage.map((value) => value / 4);
                //var msrppercentage1=msrpPercentages.map(value => value / 2);
                console.log("msrppercentage");
                console.log(msrppercentage);
                console.log("msrppercentage1");
                console.log(msrppercentage1);

                this.setState({
                  msrppercentagedata: msrppercentage1,
                });

                var estimatedresidualvalue1 = [];
                // Calculate estimated residual value for each percentage
                msrppercentage.forEach((percentage) => {
                  var estimaterv = (msrp * percentage) / 100;
                  estimatedresidualvalue1.push(estimaterv);
                });

                estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
                  parseFloat(value.toFixed(2))
                );

                this.setState({
                  estimatedresidualvalue: estimatedresidualvalue1,
                });

                this.setState({
                  estimatedresidualvaluemyname: estimatedresidualvalue1,
                });

                for (let i = 0; i < estimatedresidualvalue1.length; i++) {
                  // Construct state key dynamically
                  const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;

                  // Set state with the corresponding value
                  this.setState({
                    [stateKey]: estimatedresidualvalue1[i],
                  });
                }

                const calculateMonthlyPaymentC = (
                  totalLoanAmount,
                  termMonths,
                  monthlyInterestRate
                ) => {
                  return (
                    (totalLoanAmount * monthlyInterestRate) /
                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                  );
                };
                const calculateMonthlyPayment = (
                  totalLoanAmount,
                  termMonths,
                  pifRate,
                  endBalance
                ) => {
                  var loanAmount = totalLoanAmount;
                  var endBalance = endBalance;
                  var monthlyInterestRate = pifRate;
                  var termMonths = termMonths;
                  let monthlyPayment = 0;
                  let epsilon = 0.01; // Tolerance for convergence
                  let maxIterations = 1000; // Maximum number of iterations

                  for (let i = 0; i < maxIterations; i++) {
                    let remainingBalance = loanAmount;

                    // Calculate remaining balance using the current monthly payment
                    for (let j = 0; j < termMonths; j++) {
                      let interest = remainingBalance * monthlyInterestRate;
                      let principal = monthlyPayment - interest;
                      remainingBalance -= principal;
                    }

                    // Check if the remaining balance is close enough to the end balance
                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                      break; // Stop iteration if close enough
                    }

                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                    monthlyPayment +=
                      (remainingBalance - endBalance) / termMonths;
                  }
                  return monthlyPayment;
                };

                // Terms for calculation
                const terms = [12, 24, 36, 48, 60, 72, 84];
                const conventionalRate = this.state.conventionalrate / 100 / 12;
                const pifRate2 = this.state.pifrate / 100 / 12;
                const estimatervcArray = this.state.estimatedresidualvalue;
                let pifMonthlyPayments = [];
                let conventionalMonthlyPayments = [];
                terms.forEach((term, index) => {
                  var toalamountfinceddata =
                    this.state.totalamountfinanced.toFixed(2);
                  const pifMonthlyPayment = calculateMonthlyPayment(
                    toalamountfinceddata,
                    term,
                    pifRate2,
                    this.state.estimatedresidualvalue[index]
                  );
                  pifMonthlyPayments.push(pifMonthlyPayment);
                  // Calculate monthly payment for conventional rate
                  const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                    this.state.totalamountfinanced,
                    term,
                    conventionalRate
                  );
                  conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                });
                this.setState({
                  monthlypayment: pifMonthlyPayments,
                  monthlypaymentc: conventionalMonthlyPayments,
                });
                
              });
 //alert("catch not");
          })
 .catch((err) => 
              {
                //  alert("catch data");
                // console.log(err);
                this.setState({ loading: false });
                // Handle error here
                // alert("static1 data");
                //      alert(this.state.vinno.trim());
                if (this.state.vinno.trim() == "1HGCP2E71BA072012") 
                {
                  this.responseCalcualtion(
                    "$25,075.00",
                    "2011 Honda Accord Sdn EX",
                    "/test.png"
                  );
                  console.error("Error:", err);
                } 
                else 
                {

                  Swal({
                    text: "Sorry server is busy, Please try with override funciton.",
                    icon: "error",
                    button: "OK",
                  });
                  return false;
                }

                // alert("test");
              });

      });
    }
  };

  // handleOverrideCheckboxChange = () => {
  //   // Alert the current state value before updating
  //   // alert(this.state.isvinChecked);

  //   // Toggle the isChecked state
  //   this.setState({ isvinChecked: !this.state.isvinChecked }, () => {
  //     // Callback function is called after state is updated
  //     // alert(this.state.isvinChecked);

  //     // Check if isvinChecked is true and update vinno accordingly
  //     if (this.state.isvinChecked) {
  //       this.setState({ vinno: '1HGCY1F34RA034366' });
  //     }
  //     else{
  //       this.setState({ vinno: '' });
  //     }
  //   });
  // }

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  closecustomerPopup = () => {
    this.setState({ showcustomerPopup: false });
  };

  handleTabChange = (nextTab) => {
    alert("dhbd");
    const { currentTab, vinno } = this.state;

    if (nextTab > currentTab) {
      // Moving forward
      if (currentTab === 0 && !vinno) {
        // Check if Vin Info field is empty
        // Vin Info field is empty, prevent moving to the next tab
        alert("Please fill in the Vin Info field before proceeding.");
        return;
      }
    }
    // Proceed to the next tab
    this.setState({ currentTab: nextTab });
  };

  handleVinno = (vinnumber,buildvehiclesection) => {

if(buildvehiclesection ==true)
{
  this.setState({ isvinChecked: false });
    if (this.state.isvinChecked == false) {
      this.setState({ vinno: "" });
    }
}

    const observer = new MutationObserver((mutationsList, observer) => {
      const accordionPanel = document.querySelector(
        ".accordion-collapse.collapse"
      );
      if (accordionPanel) {
        // Execute your code here when the class is found
        accordionPanel.classList.remove("collapse");
        observer.disconnect(); // Disconnect the observer after executing the code
      }
    });

    // Start observing changes to the target node
    observer.observe(document.body, { childList: true, subtree: true });

console.log("vinnumberbyfunctioncall");
// console.log(e.target.value);

var vindata=  vinnumber;

console.log(vindata);


    this.setState({ vinno: vindata }, () => {
     

      if (vindata.length == 17) {
        this.setState({ loading: true });
        this.setState({ msrpvalue: "" });
        this.setState({ model: "" });
        this.setState({ carname: "" });
        this.setState({ year: "" });
        this.setState({ pdf_url: "" });
        this.setState({ purchasepricevalue: "" });
        this.setState({ tradeinvalue: "" });
        this.setState({ tradeinpayoff: "" });
        this.setState({ downpayment: "" });
        this.setState({ insurance: "" });
        this.setState({ otherfees: "" });
        this.setState({ salestax: "" });

      

        axios
          .get(process.env.REACT_APP_EXTERNAL_URL + `/` + vindata)
          .then((res) => {
            this.setState({ loading: false });

            // alert(res.data.status)
            if (res.data.status == false) {
              Swal({
                text: "Data is not available for this vin",
                icon: "error",
                button: "OK",
              });
              return false;
            }

            console.log(res);
            const responseData = res.data;

            // Extract the vehicle name from the response data
            const msrpValue = responseData.price;
            this.setState({ msrpvalue: msrpValue });
            const vehicleName = responseData.vehicle_name;

            const carArray = vehicleName.split(" ");

            this.setState({ model: carArray.slice(3).join(" ") });
            this.setState({ carname: carArray[1] + " " + carArray[2] });
            this.setState({ year: carArray[0] });

            this.setState({
              pdf_url:
                process.env.REACT_APP_EXTERNAL_URL + responseData.img_url,
            });

            const numericValue = parseFloat(
              msrpValue.replace(/\$|,/g, "")
            ).toFixed(2);

            var carName = vehicleName;

            //let msrpPercentages = [];

            // Call the fetchData function and handle the promise
            var msrp = "";
            this.fetchData(carName)
              .then((data) => {
                // Access the updated msrpPercentages array here

                var msrp = numericValue;
                var msrppercentage = data.map((value) => value / 2);
                // var msrppercentage1=[8,7,5,4,2,2,1];
                var msrppercentage1 = msrppercentage.map((value) => value / 4);

                 console.log("msrppercentage");
                console.log(msrppercentage);

                console.log(msrppercentage1);
                console.log("msrppercentage2");

                this.setState({
                  msrppercentagedata: msrppercentage1,
                });

                var estimatedresidualvalue1 = [];
                // Calculate estimated residual value for each percentage
                msrppercentage.forEach((percentage) => {
                  var estimaterv = (msrp * percentage) / 100;
                  estimatedresidualvalue1.push(estimaterv);
                });

                estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
                  parseFloat(value.toFixed(2))
                );

                this.setState({
                  estimatedresidualvalue: estimatedresidualvalue1,
                });

                this.setState({
                  estimatedresidualvaluemyname: estimatedresidualvalue1,
                });

                for (let i = 0; i < estimatedresidualvalue1.length; i++) {
                  // Construct state key dynamically
                  const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;

                  // Set state with the corresponding value
                  this.setState({
                    [stateKey]: estimatedresidualvalue1[i],
                  });
                }

                const calculateMonthlyPaymentC = (
                  totalLoanAmount,
                  termMonths,
                  monthlyInterestRate
                ) => {
                  return (
                    (totalLoanAmount * monthlyInterestRate) /
                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                  );
                };
                const calculateMonthlyPayment = (
                  totalLoanAmount,
                  termMonths,
                  pifRate,
                  endBalance
                ) => {
                  var loanAmount = totalLoanAmount;
                  var endBalance = endBalance;
                  var monthlyInterestRate = pifRate;
                  var termMonths = termMonths;
                  let monthlyPayment = 0;
                  let epsilon = 0.01; // Tolerance for convergence
                  let maxIterations = 1000; // Maximum number of iterations

                  for (let i = 0; i < maxIterations; i++) {
                    let remainingBalance = loanAmount;

                    // Calculate remaining balance using the current monthly payment
                    for (let j = 0; j < termMonths; j++) {
                      let interest = remainingBalance * monthlyInterestRate;
                      let principal = monthlyPayment - interest;
                      remainingBalance -= principal;
                    }

                    // Check if the remaining balance is close enough to the end balance
                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                      break; // Stop iteration if close enough
                    }

                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                    monthlyPayment +=
                      (remainingBalance - endBalance) / termMonths;
                  }

                  return monthlyPayment;
                };

                // Terms for calculation
                const terms = [12, 24, 36, 48, 60, 72, 84];
                const conventionalRate = this.state.conventionalrate / 100 / 12;
                const pifRate2 = this.state.pifrate / 100 / 12;

                //const estimatervcArray = this.state.estimatedresidualvalue;

                const estimatervcArray = this.state.estimatedresidualvalue;

                let pifMonthlyPayments = [];
                let conventionalMonthlyPayments = [];

                // estimatervcArray.forEach(estimatervcValue => {
                terms.forEach((term, index) => {
                  // // Calculate monthly payment for PIF rate
                  //var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2)- this.state.estimatedresidualvalue[index]
                  var toalamountfinceddata =
                    this.state.totalamountfinanced.toFixed(2);

                  const pifMonthlyPayment = calculateMonthlyPayment(
                    toalamountfinceddata,
                    term,
                    pifRate2,
                    this.state.estimatedresidualvalue[index]
                  );
                  pifMonthlyPayments.push(pifMonthlyPayment);
                  // Calculate monthly payment for conventional rate
                  const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                    toalamountfinceddata,
                    term,
                    conventionalRate
                  );
                  conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                });
                //});
                this.setState({
                  monthlypayment: pifMonthlyPayments,
                  monthlypaymentc: conventionalMonthlyPayments,
                });
              });

            // alert("static33 data");

          })  .catch((err) => {
                this.setState({ loading: false });
                // Handle error here
                 // alert("static34 data");

                if (this.state.vinno.trim() == "1HGCP2E71BA072012") {
                  this.responseCalcualtion(
                    "$25,075.00",
                    "2011 Honda Accord Sdn EX",
                    "/test.png"
                  );
                  console.error("Error:", err);
                } else {
                  Swal({
                    text: "Sorry server is busy, Please try with override funciton.",
                    icon: "error",
                    button: "OK",
                  });
                  return false;
                }
              });

if(buildvehiclesection==true) {
  const { currentTab, totalTabs } = this.state;
    // Logic to handle clicking the "next" button
    // alert(currentTab);
    // alert(totalTabs);
    if (currentTab < totalTabs - 1) {
      this.setState({ currentTab: currentTab + 1 });

      //alert(this.state.currentTab);
    }
}

      }
    });
  };

   // Filter makes based on the selected year
  handleYearChange = (selectedYear) => {
    // setYear(selectedYear);

        this.setState({ loading: false });


    const { excelData } = this.state;

    const makes = excelData.filter(item => item.Year == selectedYear).map(item => item.Make);

// console.log("2024");
// console.log(makes);    
// console.log(excelData);
// console.log(selectedYear);    




    this.setState({ yearData: selectedYear, makeOptions: [...new Set(makes)], selectedMake: '' });
  };


 // Handle make selection
  handleMakeChange = (selectedMake) => {
    this.setState({ selectedMake });

 const { excelData,yearData } = this.state;
    const models = excelData.filter(item => 
      item.Make == selectedMake && 
      item.Year == yearData 
      )


    .map(item => item.Model);
    

    this.setState({ selectedMake: selectedMake, modelOptions: [...new Set(models)], selectedModel: '' });
 

  };


   handleModelChange = (selectedModel) => {
    this.setState({ selectedModel: selectedModel});


  };


handleGetVin =() =>
{
  console.log("vin data");
   console.log(this.state.yearData);

if(this.state.yearData =='')
{
 Swal({
        text: "Please select Model Year",
        icon: "error",
        button: "OK",
      });
 return false;
}


if(this.state.selectedMake =='')
{
 Swal({
        text: "Please select Make",
        icon: "error",
        button: "OK",
      });
 return false;
}

if(this.state.selectedMake =='')
{
 Swal({
        text: "Please select Model",
        icon: "error",
        button: "OK",
      });
 return false;
}

   console.log(this.state.selectedMake);
   console.log(this.state.selectedModel);
const { excelData,yearData,selectedMake,selectedModel } = this.state;    
const vins = excelData.filter(item => 
  item.Make == selectedMake && 
  item.Year == yearData && 
  item.Model == selectedModel
).map(item => item.VIN);
console.log("vin");
console.log(vins[0]);
var vinno= vins[0];
this.setState({ vinno: vinno});


this.handleVinno(vinno,true);


}


  formvalidation = (e) => {
    e.preventDefault();

    if (this.state.name == "") {
      alert("Please enter first name.");
      return false;
    }
    if (this.state.surname == "") {
      alert("Please enter surname.");
      return false;
    }

    if (this.state.email == "") {
      alert("Please enter email.");
      return false;
    }

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(this.state.email) === false) {
      alert("Please enter correct email.");
      return false;
    }

    if (this.state.country == "") {
      alert("Please select country.");
      return false;
    }

    if (this.state.city == "") {
      alert("Please select city.");
      return false;
    }

    if (this.state.password == "") {
      alert("Please enter password.");
      return false;
    }

    if (this.state.cpassword == "") {
      alert("Please enter confirm password.");
      return false;
    }

    var Varnewsletter = 0;
    if (this.state.newsletter) {
      Varnewsletter = 1;
    }

    if (this.state.password != this.state.cpassword) {
      alert("Both passwords should be same.");
      return false;
    }

    if (this.state.signupvalidation == false) {
      alert("Please choose signup.");
      return false;
    }

    const clientData = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      country: this.state.country,
      city: this.state.city,
      password: this.state.password,
      newsletter: Varnewsletter,
    };

    axios
      .post(
        process.env.REACT_APP_BASE_URL + `/authenticationAPI/ClientRegistraion`,
        clientData
      )
      .then((res) => {
        if (res.data.Status) this.setState({ redirect: true });
        else alert(res.data.Message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { currentTab, vinno, totalTabs } = this.state;

    const { msrpvalue } = this.state;

    const { t } = this.props;
    var loanAmount = parseFloat(this.state.totalamountfinanced); //this.state.totalamountfinanced/
    var interestRate = parseFloat(this.state.pifrate); //this.state.pifrate
    var termMonths = parseInt(this.state.selectedTerms); //
    // var endBalanceInput =form.querySelector('.end-balance').value;
    var endBalance =
      this.state.estimatedresidualvalue[this.state.selectedIndex] !==
        undefined &&
      this.state.estimatedresidualvaluemyname[this.state.selectedIndex] > 0
        ? `${this.state.estimatedresidualvalue[
            this.state.selectedIndex
          ].toFixed(2)}`
        : 0;

    //var monthlyPaymentId = loanType + 'MonthlyPayment';
    var monthlyPayment =
      this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
      this.state.monthlypayment[this.state.selectedIndex] > 0
        ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
        : 0;

    var monthlyInterestRate = interestRate / 100 / 12;
    var remainingBalance = loanAmount;
    var amortizationHTML = "";
    var totalPrincipal = 0;
    var totalInterest = 0;
    var totalMonthlyPayment = 0;
    var tabledata = "";

    for (var i = 1; i <= termMonths; i++) {
      var interest = remainingBalance * monthlyInterestRate;
      var principal = monthlyPayment - interest;
      remainingBalance -= principal;
      totalPrincipal += principal;
      totalInterest += interest;
      totalMonthlyPayment += monthlyPayment;

      // Round monthly payment to the nearest integer
      monthlyPayment = Math.round(monthlyPayment);

      // Format numbers with commas as thousand separators and two decimal places
      principal = principal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      interest = interest.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      var monthlyPaymentFormatted = monthlyPayment.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      var remainingBalanceFormatted = remainingBalance.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      // If it's the last month and it's a balloon loan, set the remaining balance to the end balance
      if (i === termMonths) {
        remainingBalance = endBalance;
        remainingBalanceFormatted = remainingBalance.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (i === termMonths) {
        // If it's the last month and it's not a balloon loan, set the remaining balance to 0
        remainingBalance = 0;
        remainingBalanceFormatted = "0.00";
      }

      amortizationHTML += "<tr>";
      amortizationHTML += "<td>" + i + "</td>"; // Month
      amortizationHTML += "<td>$" + principal + "</td>"; // Principal
      amortizationHTML += "<td>$" + interest + "</td>"; // Interest
      amortizationHTML += "<td>$" + monthlyPaymentFormatted + "</td>"; // Monthly Payment
      amortizationHTML += "<td>$" + remainingBalanceFormatted + "</td>"; // Remaining Balance
      amortizationHTML += "</tr>";
    }

    // Add total row
    // Convert the value to a number if it's not already
    const numericValue = parseFloat(totalMonthlyPayment);
    let formattedNumber = "";
    // Check if the value is a valid number
    if (!isNaN(numericValue)) {
      // Convert the number to a fixed decimal format
      const fixedDecimalNumber = numericValue.toFixed(2);

      // Format the fixed decimal number
      formattedNumber = parseFloat(fixedDecimalNumber).toLocaleString("en-US");
    } else {
      formattedNumber = "";
    }

    amortizationHTML += "<tr>";
    amortizationHTML += "<td><strong>Total</strong></td>"; // Month
    amortizationHTML +=
      "<td><strong>$" +
      totalPrincipal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      "</strong></td>"; // Total Principal
    amortizationHTML +=
      "<td><strong>$" +
      totalInterest.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      "</strong></td>"; // Total Interest
    amortizationHTML += "<td><strong>$" + formattedNumber + "</strong></td>"; // Total Monthly Payment
    amortizationHTML +=
      "<td><strong>$" +
      remainingBalance.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      "</strong></td>"; // Total Remaining Balance
    amortizationHTML += "</tr>";

    tabledata =
      '<table id="customers" style="width:100%"><tr><th>Month</th><th>Principal</th><th>Interest</th><th>Monthly Payment</th><th>Remaining Balance</th></tr>' +
      amortizationHTML +
      "<table>";

    const numbersAndDecimaltf = this.state.totalamountfinanced.toString();
    const sectionstf = numbersAndDecimaltf.split(".");
    const integerParttf = sectionstf[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decimalParttf = sectionstf[1] ? sectionstf[1].substring(0, 2) : "";

    const formattedValuetf =
      sectionstf.length === 2
        ? `${integerParttf}.${decimalParttf}`
        : integerParttf;

    // alert(formattedValuetf)
    // if (Number(formattedValuetf.replace(/,/g, '')) <= 0) {
    //   Swal({
    //     text: 'Total Amount Financed should not be negative',
    //     icon: 'error',
    //     button: 'OK',
    //   });
    //   // return false;
    // }

    const numbersAndDecimalstp = this.state.salestaxpercentage.toString();
    const sectionsstp = numbersAndDecimalstp.split(".");
    const integerPartstp = sectionsstp[0];
    const decimalPartstp = sectionsstp[1] ? sectionsstp[1].substring(0, 2) : "";

    const formattedValuestp =
      sectionsstp.length === 2
        ? `${integerPartstp}.${decimalPartstp}`
        : integerPartstp;

    const numbersAndDecimalct = this.state.conventionalrate.toString();
    const sectionsct = numbersAndDecimalct.split(".");
    const integerPartct = sectionsct[0];
    const decimalPartct = sectionsct[1] ? sectionsct[1].substring(0, 2) : "";

    const formattedValuect =
      sectionsct.length === 2
        ? `${integerPartct}.${decimalPartct}`
        : integerPartct;

    const numbersAndDecimalpft = this.state.pifrate.toString();
    const sectionspft = numbersAndDecimalpft.split(".");
    const integerPartpft = sectionspft[0];
    const decimalPartpft = sectionspft[1] ? sectionspft[1].substring(0, 2) : "";

    const formattedValuepft =
      sectionspft.length === 2
        ? `${integerPartpft}.${decimalPartpft}`
        : integerPartpft;

    return (
      <>
        <LoginHeader />
        {/*<div style={{ textAlign: "center",color: "white", fontSize: "20px", fontWeight: "600",paddingTop: "25px",background: "#002f6c",background: "#002f6c" }}><h1 style={{color:"white"}}>Pay IT Forward - PIF</h1><hr/></div>*/}
        <FormWizard
          shape="circle"
          color="#e74c3c"
          onComplete={this.state.handleComplete}
        >
          {/*<FormWizard.TabContent title="Personal details" icon="ti-user">
           <div style={{ textAlign: "left", paddingBottom: "10px" }}>
           <label>
            Name
            <span
              style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
            >
              *
            </span>
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            value={this.state.name}
             onChange={(e)=> this.setState({ name: e.target.value})}
          />
          </div>

<div style={{ textAlign: "left", paddingBottom: "10px" }}>
           <label>
            Email
            <span
              style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
            >
              *
            </span>
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            value={this.state.email}
            onChange={(e)=> this.setState({ email: e.target.value})}
          />
</div>



<div style={{ textAlign: "left", paddingBottom: "10px" }}>
           <label>
            Phone Number
            <span
              style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
            >
              *
            </span>
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            value={this.state.phone}
            onChange={(e)=> this.setState({ phone: e.target.value})}
          />
</div>



  {/* <div style={{ textAlign: "left" }}>
        <label>
          Gender
          <span style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}>
            *
          </span>
        </label>
      </div>
      <div style={{ display: "flex",justifyContent: "left" }}>
        <label style={{ display: "block" }}>
          <input style={{ width: "20px" }}
            className="form-control"
            type="radio"
            value="male"
            selected
            checked={this.state.gender === "male"}
           onChange={(e)=> this.setState({ gender: e.target.value})}
          />{" "}
          Male
        </label>&nbsp;&nbsp;
        <label style={{ display: "block" }}>
          <input style={{ width: "20px" }}
            className="form-control"
            type="radio"
            value="female"
            checked={this.state.gender === "female"}
            onChange={(e)=> this.setState({ gender: e.target.value})}
          />{" "}
          Female
        </label>
      </div> */}

          {/*isValid={this.checkValidateTab()} validationError={this.errorMessages()}*/}

          {/*    </FormWizard.TabContent> */}

          {currentTab === 0 && (
            <div style={{ width: "40%", margin: "0 auto" }}>
              <FormWizard.TabContent
                isValid={this.checkValidateTab()}
                validationError={this.errorMessages()}
                title="Vin Info"
                icon="ti-settings"
                currentTab={this.state.currentTab}
                totalTabs={this.state.totalTabs}
                onTabChange={this.handleTabChange}
              >
                <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                  <label>
                    Vin No.
                    <span
                      style={{
                        color: "#e74c3c",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <br />
                  <input
                    title="vin no."
                    className="form-control"
                    type="text"
                    id="vinnoid"
                    value={this.state.vinno}
                    onChange={(e) =>this.handleVinno(e.target.value,false)}
                  
                    required
                  />
                </div>
                <div class="text-left rem-main-div">
                  <a class="btn modal-trigger text-left w-100 pad-0">
                    <span>
                      <input
                        checked={this.state.isvinChecked}
                        onChange={this.handleOverrideCheckboxChange}
                        type="checkbox"
                        id="override"
                        class="rem-me-input"
                      />
                      <label for="override" class="color-cus-gray">
                        Override
                      </label>
                    </span>
                  </a>
                </div>

                <button
                  style={{
                    width: "140px",
                    padding: "5px",
                    border: "1px solid #002f6c",
                    background: "#002f6c",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "15px",
                    fontWeight: "600",
                  }}
                  currentTab={this.state.currentTab}
                  onClick={this.handleNextButtonClick}
                >
                  Proceed
                </button>



  <div class="divider"  >
  <br/>
            <h2>--------or----------</h2>
        </div>
        <br/>
        <br/>


 <div  >

        <h1 style={{fontWeight:"600",fontSize:"28px"}}>Vehicle Select</h1>
       
       

            <select id="yearSelect" class="form-control" value={this.state.yearData} onChange={(e) => this.handleYearChange(e.target.value)}>
            <option value="">Select Model Year</option>
            {this.getYears().map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>

        


            <select id="makeSelect" class="form-control" value={this.state.selectedMake} onChange={(e) => this.handleMakeChange(e.target.value)}>
            <option value="">Select Make</option>
            {this.state.makeOptions.map(make => (
              <option key={make} value={make}>{make}</option>
            ))}
          </select>




            <select id="modelSelect" class="form-control"  value={this.state.selectedModel} onChange={(e) => this.handleModelChange(e.target.value)}>
            <option value="">Select Model</option>
            {this.state.modelOptions.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
            <button type="button"  style={{
                    width: "140px",
                    padding: "5px",
                    border: "1px solid #002f6c",
                    background: "#002f6c",
                    color: "white",
                    borderRadius: "5px",
                    marginTop: "50px",
                    marginLeft: "0px",
                    fontWeight: "600",
                  }}  onClick={this.handleGetVin}>Get Quote</button>
       
    </div>




              </FormWizard.TabContent>
            </div>
          )}
          {/*<FormWizard.TabContent title="Vehicle Info" icon="ti-settings"  >
          

 <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
           <label>
            Model Name
           
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"demo"}
             onChange={(e)=> this.setState({ modalname: e.target.value})}
          />
          </div>

          <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
           <label>
            Model No.
            
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"xyz"}
             onChange={(e)=> this.setState({ modalno: e.target.value})}
          />
          </div>


          <div style={{ textAlign: "left" , paddingBottom: "10px"}}>
           <label>
            Model Year
            
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"1992"}
             onChange={(e)=> this.setState({ modalyear: e.target.value})}
          />
          </div>

            <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
           <label>
            Model Type
           
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"demo"}
             onChange={(e)=> this.setState({ modaltype: e.target.value})}
          />
          </div>

            <div style={{ textAlign: "left" , paddingBottom: "10px", display: "none" }}>
           <label>
            Engine Size
            
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"demo"}
             onChange={(e)=> this.setState({ modaltype: e.target.value})}
          />
          </div>

            <div style={{ textAlign: "left" , paddingBottom: "10px" , display: "none" }}>
           <label>
            Engine Type
           
          </label>
          <br />
          <input
          disabled
            className="form-control"
            type="text"
            value={"demo"}
             onChange={(e)=> this.setState({ modaltype: e.target.value})}
          />
          </div>


        </FormWizard.TabContent> */}

          {currentTab === 1 && (
            <>
              <button
                style={{
                  width: "25px",
                  padding: "5px",
                  border: "1px solid #002f6c",
                  background: "#002f6c",
                  color: "white",
                  borderRadius: "5px",
                  fontWeight: "600",
                  position: "absolute",
                  left: "40px",
                }}
                currentTab={this.state.currentTab}
                onClick={this.handlePrevButtonClick}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </button>

              <FormWizard.TabContent
                title="Last step"
                icon="ti-check"
                active={"true"}
                currentTab={this.state.currentTab}
                totalTabs={this.state.totalTabs}
                onTabChange={this.handleTabChange}
              >
                {this.state.loading && (
                  <>
                    <div
                      class=""
                      style={{
                        color: "black",
                        position: "fixed",
                        top: "15%",
                        left: "0",
                        fontSize: "22px",
                        width: "100%",
                        height: "100%",
                        zIndex: "9999",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading...
                    </div>
                    <div className="loader-overlay">
                      <div class="spinner-square">
                        <div class="square-1 square"></div>
                        <div class="square-2 square"></div>
                        <div class="square-3 square"></div>
                      </div>
                    </div>
                  </>
                )}

                <div style={{ width: "100%" }}>
                  <br />
                  {
                    <Accordion flush className="custom-accordion">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {this.state.year} {this.state.carname}{" "}
                          {this.state.model}{" "}
                          {this.state.msrpvalue != undefined ? "- MSRP:" : ""}{" "}
                          {this.state.msrpvalue}
                        </Accordion.Header>
                        <Accordion.Body
                          style={{
                            textAlign: "left",
                            maxHeight: "260px",
                            overflowY: "scroll",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {
                              <div class="horizontal-table">
                                <div class="table-header">
                                  <div class="table-header-item">Car Name</div>
                                  <div class="table-header-item">Year</div>
                                  <div class="table-header-item">Model</div>
                                  <div class="table-header-item">MSRP</div>
                                </div>
                                <div class="table-content">
                                  <div class="table-row">
                                    <div class="table-cell">
                                      {this.state.carname}
                                    </div>
                                    <div class="table-cell">
                                      {this.state.year}
                                    </div>
                                    <div class="table-cell">
                                      {this.state.model}
                                    </div>
                                    <div class="table-cell">
                                      {this.state.msrpvalue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            <div style={{ width: "50%",display:"flex",justifyContent: "center" }}>
                              <button onClick={this.openInNewTab}>
                                <img
                                  src={this.state.pdf_url}
                                  alt="Sticker Image"
                                  style={{ width: "100%", maxHeight: "200px" }}


                                />
                              </button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  }
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "20%" }}>
                    <br />

                    <br />

                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingBottom: "20px",
                        borderBottom: "1px solid #dee2e6",
                        marginBottom: "40px",
                      }}
                    >
                      Calculator
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Purchase Price:</label>
                      <br />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="purchase price"
                          className="form-control"
                          type="text"
                          value={
                            this.state.purchasepricevalue
                              ? this.state.purchasepricevalue
                              : ""
                          }
                          onChange={this.handlePurchasevalue}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Trade-in Value:</label>
                      <br />

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="trade in value"
                          className="form-control"
                          type="text"
                          value={
                            this.state.tradeinvalue
                              ? this.state.tradeinvalue
                              : ""
                          }
                          onInput={this.handleTradeinvalue}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Trade-in Payoff:</label>
                      <br />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="purchase payoff"
                          className="form-control"
                          type="text"
                          value={
                            this.state.tradeinpayoff
                              ? this.state.tradeinpayoff
                              : ""
                          }
                          onChange={this.handleTradeinpayoff}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Down Payment:</label>
                      <br />

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="downpayment"
                          className="form-control"
                          type="text"
                          value={
                            this.state.downpayment ? this.state.downpayment : ""
                          }
                          onChange={this.handleDownpayment}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>
                        Sales Tax
                        <span
                          style={{
                            color: "red",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span>
                        :
                      </label>
                      <br />
                      <div style={{ display: "flex" }}>
                        <input
                          title="sales tax percentage"
                          style={{ width: "35%", marginRight: "5%" }}
                          className="form-control"
                          type="text"
                          value={formattedValuestp}
                          onChange={this.handleSalestaxpercentage}
                        />

                        <input
                          title="sales tax value"
                          style={{ width: "60%" }}
                          className="form-control"
                          disabled
                          type="text"
                          value={
                            this.state.salestax
                              ? parseFloat(this.state.salestax).toFixed(2)
                              : ""
                          }
                          onChange={this.handleSalestax}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Insurance/warranties:</label>
                      <br />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="insurance"
                          className="form-control"
                          type="text"
                          value={
                            this.state.insurance ? this.state.insurance : ""
                          }
                          onChange={this.handleInsurance}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>All other fees:</label>
                      <br />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          title="all other fees"
                          className="form-control"
                          type="text"
                          value={
                            this.state.otherfees ? this.state.otherfees : ""
                          }
                          onChange={this.handleAllotherfees}
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Total Amount Financed :</label>
                      <br />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>

                        <input
                          title="total amount financed"
                          disabled
                          className="form-control"
                          type="text"
                          value={
                            formattedValuetf.replace(/,/g, "") > 0
                              ? formattedValuetf
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              totalamountfinanced: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>Conventional Rate:</label>
                      <br />

                      <div class="input-group">
                        <input
                          title="conventional rate"
                          className="form-control"
                          type="text"
                          value={formattedValuect}
                          onChange={this.handleConventionalRateChange}
                        />

                        <div class="input-group-append">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                      <label>PIF Rate:</label>
                      <br />
                      <div class="input-group">
                        <input
                          title="pif rate"
                          disabled
                          className="form-control"
                          type="text"
                          value={formattedValuepft}
                        />

                        <div class="input-group-append">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>

                    {/*} <div style={{ textAlign: "left" , paddingBottom: "5px" }}>
           <label>
            Annual Mileage:
            
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            value={"12,000"}
             
             onChange={(e)=> this.setState({ modaltype: e.target.value})}
          />
          </div>

           <div style={{ textAlign: "left"  , paddingBottom: "5px"}}>
           <label>
            Current odometer:
           
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            value={"0-12000"}
             
             onChange={(e)=> this.setState({ modaltype: e.target.value})}
          />
          </div> */}
                    <div style={{ textAlign: "left" }}>
                      <label>Notes:</label>
                      <br />
                      <textarea
                        title="notes"
                        class="form-control"
                        value={this.state.notes}
                        onChange={(e) =>
                          this.setState({ notes: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div style={{ width: "75%" }}>
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingBottom: "20px",
                      }}
                    >
                      Payment Options
                    </div>

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Term</th>
                          <th style={{ width: "28%" }}>
                            Estimated Residual value
                          </th>
                          <th>PIF Payment</th>
                          <th>Conventional Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={
                            this.state.selectedOption === "12"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>12 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput12"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[0]
                                    ? this.state.estimatedresidualvalue[0]
                                    : ""
                                }
                                onChange={this.handlerv12}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(12)}
                                />
                                {this.state.showReset12 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(12)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "12" ? (
                                <span>
                                  {this.state.monthlypayment[0] !== undefined &&
                                  this.state.monthlypayment[0] > 0 ? (
                                    `$${this.state.monthlypayment[0].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[0] !== undefined &&
                                  this.state.monthlypayment[0] > 0 ? (
                                    `$${this.state.monthlypayment[0].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                value="12"
                                checked={this.state.selectedOption === "12"}
                                onChange={() => this.changeradio(0, "12")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "12" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[0]) &&
                                this.state.monthlypaymentc[0] !== undefined &&
                                this.state.monthlypaymentc[0] !== undefined &&
                                this.state.monthlypaymentc[0] > 0 ? (
                                  `$${this.state.monthlypaymentc[0].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[0]) &&
                                this.state.monthlypaymentc[0] !== undefined &&
                                this.state.monthlypaymentc[0] > 0 ? (
                                  `$${this.state.monthlypaymentc[0].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr
                          style={
                            this.state.selectedOption === "24"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>24 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput24"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[1]
                                    ? this.state.estimatedresidualvalue[1]
                                    : ""
                                }
                                onChange={this.handlerv24}
                              />

                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(24)}
                                />
                                {this.state.showReset24 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(24)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "24" ? (
                                <span>
                                  {this.state.monthlypayment[1] !== undefined &&
                                  this.state.monthlypayment[1] > 0 ? (
                                    `$${this.state.monthlypayment[1].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[1] !== undefined &&
                                  this.state.monthlypayment[1] > 0 ? (
                                    `$${this.state.monthlypayment[1].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                value="24"
                                checked={this.state.selectedOption === "24"}
                                onChange={() => this.changeradio(1, "24")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "24" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[1]) &&
                                this.state.monthlypaymentc[1] !== undefined &&
                                this.state.monthlypaymentc[1] > 0 ? (
                                  `$${this.state.monthlypaymentc[1].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[1]) &&
                                this.state.monthlypaymentc[1] !== undefined &&
                                this.state.monthlypaymentc[1] > 0 ? (
                                  `$${this.state.monthlypaymentc[1].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={
                            this.state.selectedOption === "36"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>36 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput36"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[2]
                                    ? this.state.estimatedresidualvalue[2]
                                    : ""
                                }
                                onChange={this.handlerv36}
                              />

                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(36)}
                                />
                                {this.state.showReset36 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(36)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "36" ? (
                                <span>
                                  {this.state.monthlypayment[2] !== undefined &&
                                  this.state.monthlypayment[2] > 0 ? (
                                    `$${this.state.monthlypayment[2].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[2] !== undefined &&
                                  this.state.monthlypayment[2] > 0 ? (
                                    `$${this.state.monthlypayment[2].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                type="radio"
                                value="36"
                                checked={this.state.selectedOption === "36"}
                                onChange={() => this.changeradio(2, "36")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "36" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[2]) &&
                                this.state.monthlypaymentc[2] !== undefined &&
                                this.state.monthlypaymentc[2] > 0 ? (
                                  `$${this.state.monthlypaymentc[2].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[2]) &&
                                this.state.monthlypaymentc[2] !== undefined &&
                                this.state.monthlypaymentc[2] > 0 ? (
                                  `$${this.state.monthlypaymentc[2].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={
                            this.state.selectedOption === "48"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>48 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput48"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[3]
                                    ? this.state.estimatedresidualvalue[3]
                                    : ""
                                }
                                onChange={this.handlerv48}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(48)}
                                />
                                {this.state.showReset48 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(48)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "48" ? (
                                <span>
                                  {this.state.monthlypayment[3] !== undefined &&
                                  this.state.monthlypayment[3] > 0 ? (
                                    `$${this.state.monthlypayment[3].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[3] !== undefined &&
                                  this.state.monthlypayment[3] > 0 ? (
                                    `$${this.state.monthlypayment[3].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                type="radio"
                                value="48"
                                checked={this.state.selectedOption === "48"}
                                onChange={() => this.changeradio(3, "48")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "48" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[3]) &&
                                this.state.monthlypaymentc[3] !== undefined &&
                                this.state.monthlypaymentc[3] > 0 ? (
                                  `$${this.state.monthlypaymentc[3].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[3]) &&
                                this.state.monthlypaymentc[3] !== undefined &&
                                this.state.monthlypaymentc[3] > 0 ? (
                                  `$${this.state.monthlypaymentc[3].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr
                          style={
                            this.state.selectedOption === "60"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>60 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput60"
                                disabled
                                className="form-control"
                                type="text"
                                value={this.state.estimatedresidualvalue[4]}
                                onChange={this.handlerv60}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(60)}
                                />
                                {this.state.showReset60 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(60)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "60" ? (
                                <span>
                                  {this.state.monthlypayment[4] !== undefined &&
                                  this.state.monthlypayment[4] > 0 ? (
                                    `$${this.state.monthlypayment[4].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[4] !== undefined &&
                                  this.state.monthlypayment[4] > 0 ? (
                                    `$${this.state.monthlypayment[4].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                type="radio"
                                value="60"
                                checked={this.state.selectedOption === "60"}
                                onChange={() => this.changeradio(4, "60")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "60" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[4]) &&
                                this.state.monthlypaymentc[4] !== undefined &&
                                this.state.monthlypaymentc[4] > 0 ? (
                                  `$${this.state.monthlypaymentc[4].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[4]) &&
                                this.state.monthlypaymentc[4] !== undefined &&
                                this.state.monthlypaymentc[4] > 0 ? (
                                  `$${this.state.monthlypaymentc[4].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr
                          style={
                            this.state.selectedOption === "72"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>72 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput72"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[5]
                                    ? this.state.estimatedresidualvalue[5]
                                    : ""
                                }
                                onChange={this.handlerv72}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(72)}
                                />
                                {this.state.showReset72 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(72)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "72" ? (
                                <span>
                                  {this.state.monthlypayment[5] !== undefined &&
                                  this.state.monthlypayment[5] > 0 ? (
                                    `$${this.state.monthlypayment[5].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[5] !== undefined &&
                                  this.state.monthlypayment[5] > 0 ? (
                                    `$${this.state.monthlypayment[5].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                value="72"
                                checked={this.state.selectedOption === "72"}
                                onChange={() => this.changeradio(5, "72")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "72" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[5]) &&
                                this.state.monthlypaymentc[5] !== undefined &&
                                this.state.monthlypaymentc[5] > 0 ? (
                                  `$${this.state.monthlypaymentc[5].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[5]) &&
                                this.state.monthlypaymentc[5] !== undefined &&
                                this.state.monthlypaymentc[5] > 0 ? (
                                  `$${this.state.monthlypaymentc[5].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={
                            this.state.selectedOption === "84"
                              ? { background: "#cccccc9e" }
                              : {}
                          }
                        >
                          <td>84 Month</td>
                          <td>
                            <div class="input-group ">
                              <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                              </div>
                              <input
                                id="editableInput84"
                                disabled
                                className="form-control"
                                type="text"
                                value={
                                  this.state.estimatedresidualvalue[6] !==
                                  undefined
                                    ? this.state.estimatedresidualvalue[6]
                                    : ""
                                }
                                onChange={this.handlerv84}
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              >
                                <FontAwesomeIcon
                                  title={"edit"}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  icon={faPencilAlt}
                                  onClick={() => this.editDataa(84)}
                                />
                                {this.state.showReset84 && (
                                  <FontAwesomeIcon
                                    title={"reset"}
                                    icon={faUndo}
                                    onClick={() => this.resetDataa(84)}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            {this.state.purchasepricevalue.replace(/,/g, "") !==
                              undefined &&
                            this.state.purchasepricevalue.replace(/,/g, "") >
                              0 ? (
                              this.state.selectedOption === "84" ? (
                                <span>
                                  {this.state.monthlypayment[6] !== undefined &&
                                  this.state.monthlypayment[6] > 0 ? (
                                    `$${this.state.monthlypayment[6].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span>
                                  {this.state.monthlypayment[6] !== undefined &&
                                  this.state.monthlypayment[6] > 0 ? (
                                    `$${this.state.monthlypayment[6].toFixed(
                                      2
                                    )}`
                                  ) : (
                                    <div className="tooltip">
                                      <span className="question-mark">?</span>
                                      <span className="tooltip-text">
                                        Purchase price should be greator than
                                        end balance
                                      </span>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : (
                              <span>
                                {
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                }
                              </span>
                            )}

                            <label className="ml-2">
                              <input
                                style={{
                                  width: "30px",
                                  minHeight: "unset",
                                  height: "30px",
                                }}
                                className=" form-control option-input checkbox"
                                type="radio"
                                value="84"
                                checked={this.state.selectedOption === "84"}
                                onChange={() => this.changeradio(6, "84")}
                              />{" "}
                            </label>
                          </td>
                          <td>
                            {this.state.selectedOption === "84" ? (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[6]) &&
                                this.state.monthlypaymentc[6] !== undefined &&
                                this.state.monthlypaymentc[6] > 0 ? (
                                  `$${this.state.monthlypaymentc[6].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span>
                                {!isNaN(this.state.monthlypaymentc[6]) &&
                                this.state.monthlypaymentc[6] !== undefined &&
                                this.state.monthlypaymentc[6] > 0 ? (
                                  `$${this.state.monthlypaymentc[6].toFixed(2)}`
                                ) : (
                                  <div className="tooltip">
                                    <span className="question-mark">?</span>
                                    <span className="tooltip-text">
                                      Amount financed required
                                    </span>
                                  </div>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />

                    {this.state.showcustomerPopup && (
                      <div className="popup customer">
                        <div className="popup-content">
                          {/* Popup content */}
                          <div style={{ textAlign: "center" }}>
                            Customer details{" "}
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Name
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.name}
                            

                              onChange={(e) =>
 this.setState({ name: [e.target.value] })
}

                            />
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Email
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.email}
                              onChange={(e) =>
                                
   this.setState({ email: [e.target.value] })
                              }
                            />
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Phone Number
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.phone}
                              onChange={(e) =>
                                

   this.setState({ phone: [e.target.value] })
                              }
                            />
                          </div>


                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Address
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.address}
                              onChange={(e) =>
                                
   this.setState({ address: [e.target.value] })
    
                              }
                            />
                            </div>


{
this.state.email.length>0 && this.state.coapplicantmember &&
 <>
 <div style={{ textAlign: "center" }}>
                            Customer details{" "}
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Name
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.name1}
                            

                              onChange={(e) =>
 this.setState({ name1: [e.target.value] })
}

                            />
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Email
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.email1}
                              onChange={(e) =>
                                
   this.setState({ email1: [e.target.value] })
                              }
                            />
                          </div>
                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Phone Number
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.phone1}
                              onChange={(e) =>
                                

   this.setState({ phone1: [e.target.value] })
                              }
                            />
                          </div>


                          <div
                            style={{ textAlign: "left", paddingBottom: "10px" }}
                          >
                            <label>
                              Address
                              <span
                                style={{
                                  color: "#e74c3c",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.address1}
                              onChange={(e) =>
                                
   this.setState({ address1: [e.target.value] })
    
                              }
                            />
                            </div>
</>
}



<input type="checkbox" onChange={this.appendcoapplicant} />
                          <br />
                          Would You Like To Accept Quote? &nbsp;&nbsp;
                          <span
                            class="close-icon"
                            onClick={this.closecustomerPopup}
                          >
                            X
                          </span>
                        </div>
                        <div className="popup-buttons">
                          {/* "Next" button */}
                          <button
                            onClick={this.handleNextClick1}
                            style={{
                              borderColor: "rgb(0 0 255)",
                              borderRadius: "4px",
                              boxSizing: "border-box",
                              borderWidth: "2px",
                              backgroundColor: "transparent",
                              fontSize: "14px",
                              fontWeight: "600",
                              padding: "6px 12px",
                              color: "white",
                              minWidth: "140px",
                              marginRight: "5px",
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}

                    {this.state.showPopup && (
                      <div className="popup">
                        <div className="popup-content">
                          {/* Popup content */}
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div>
                              Loan Term: {this.state.selectedTerms} {"Month"}
                            </div>
                            <div>PIF Rate: {this.state.pifrate}</div>
                            <div>
                              Monthly Pif Amount:{" "}
                              {
                                <span>
                                  {this.state.monthlypayment[
                                    this.state.selectedIndex
                                  ] !== undefined &&
                                  this.state.monthlypayment[
                                    this.state.selectedIndex
                                  ] > 0 ? (
                                    `$${this.state.monthlypayment[
                                      this.state.selectedIndex
                                    ].toFixed(2)}`
                                  ) : (
                                    <div className="tooltip">
                                      <span
                                        className="question-mark"
                                        style={{ color: "white" }}
                                      >
                                        ?
                                      </span>
                                      <span
                                        className="tooltip-text"
                                        style={{ background: "black" }}
                                      >
                                        Amount financed required
                                      </span>
                                    </div>
                                  )}
                                </span>
                              }
                            </div>
                          </div>
                          <div
                            class="horizontal-table1"
                            style={{ display: "none" }}
                          >
                            <div class="table-header1">
                              <div class="table-header-item">MSRP</div>
                              <div class="table-header-item">PIF rate</div>
                              <div class="table-header-item">
                                Monthly Pif Amount
                              </div>
                              {/* <div class="table-header-item">Monthly Conventional Amount</div>*/}
                              {/*<div class="table-header-item">Total Estimated Value</div>*/}
                              <div class="table-header-item">Loan Term</div>
                            </div>
                            <div class="table-content1">
                              <div class="table-row1">
                                <div class="table-cell">
                                  ${this.state.msrpvalue}
                                </div>
                                <div class="table-cell">
                                  ${this.state.pifrate}
                                </div>
                                <div class="table-cell">
                                  {
                                    <span>
                                      {this.state.monthlypayment[
                                        this.state.selectedIndex
                                      ] !== undefined &&
                                      this.state.monthlypayment[
                                        this.state.selectedIndex
                                      ] > 0 ? (
                                        `$${this.state.monthlypayment[
                                          this.state.selectedIndex
                                        ].toFixed(2)}`
                                      ) : (
                                        <div className="tooltip">
                                          <span
                                            className="question-mark"
                                            style={{ color: "white" }}
                                          >
                                            ?
                                          </span>
                                          <span
                                            className="tooltip-text"
                                            style={{ background: "black" }}
                                          >
                                            Amount financed required
                                          </span>
                                        </div>
                                      )}
                                    </span>
                                  }
                                </div>
                                {/*<div class="table-cell">


{
  <span>
    {this.state.monthlypaymentc[this.state.selectedIndex] !== undefined && this.state.monthlypaymentc[this.state.selectedIndex] !== 0 ? `$${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}` : (
      <div className="tooltip">
        <span className="question-mark" style={{color:"white"}}>?</span>
        <span className="tooltip-text" style={{background:"black"}}>Amount financed required</span>
      </div>
    )}
  </span> 

}



            </div>*/}
                                {/*<div class="table-cell">{this.state.totalamountfinanced}</div>*/}
                                <div class="table-cell">
                                  {this.state.selectedTerms} {"Month"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div
                            style={{
                              maxHeight: "200px",
                              overflowY: "scroll",
                              marginBottom: "10px",
                            }}
                            dangerouslySetInnerHTML={{ __html: tabledata }}
                          />
                          Would You Like To Accept Quote? &nbsp;&nbsp;
                          <span class="close-icon" onClick={this.closePopup}>
                            X
                          </span>
                        </div>
                        <div className="popup-buttons">
                          {/* "Next" button */}
                          <button
                            onClick={this.handleNextClick}
                            style={{
                              borderColor: "rgb(0 0 255)",
                              borderRadius: "4px",
                              boxSizing: "border-box",
                              borderWidth: "2px",
                              backgroundColor: "transparent",
                              fontSize: "14px",
                              fontWeight: "600",
                              padding: "6px 12px",
                              color: "white",
                              minWidth: "140px",
                              marginRight: "5px",
                            }}
                          >
                            Accept Quote
                          </button>
                          {/* "Cancel" button */}
                          <button
                            onClick={this.handleCancelClick}
                            style={{
                              borderColor: "rgb(0 0 255)",
                              borderRadius: "4px",
                              boxSizing: "border-box",
                              borderWidth: "2px",
                              backgroundColor: "transparent",
                              fontSize: "14px",
                              fontWeight: "600",
                              padding: "6px 12px",
                              color: "white",
                              minWidth: "140px",
                              marginRight: "5px",
                            }}
                          >
                            Decline Quote
                          </button>
                        </div>
                      </div>
                    )}

                    <button
                      style={{ background: "#002f6c", color: "white" }}
                      class="btn btn-outline-secondary"
                      type="button"
                      onClick={this.finalQuote}
                    >
                      Confirm Quote
                    </button>

                    <div
                      style={{
                        marginTop: "40px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Customize your Pay-It-forward Loan!
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      GAIN more Equity when you CLICK On THE pencil ICON AND
                      Reduce The Residual Value(END BALANCE).
                    </div>
                    {this.state.showCancelMessage && (
                      <div
                        className="message"
                        style={{
                          fontSize: "1.1rem",
                          margin: "10px",
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        Thank you for visit, Please visit next time.
                      </div>
                    )}

                    {this.state.showAcceptMessage && (
                      <div
                        className="message"
                        style={{
                          fontSize: "1.1rem",
                          margin: "10px",
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        Quote accepted
                      </div>
                    )}
                  </div>
                </div>
              </FormWizard.TabContent>

              <div style={{ display: "flex", paddingTop: "30px" }}>
                <a
                  href="/dashboard"
                  style={{
                    color: "red",
                    fontSize: "24px",
                    fontWeight: "bold",
                    margin: "0 auto",
                    display: "flex",
                  }}
                >
                  <img
                    src="/assets/img/logo.png"
                    style={{ width: "250px" }}
                    alt="Logo"
                  />
                </a>
              </div>
            </>
          )}
        </FormWizard>

        {/* add style */}

        <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
      </>
    );
  }
}

export default Dashboard;
